export const getPolygonFromString = (str) => {
  if (str) {
    const polygonStr = (str || '')
      .replace('POLYGON', '')
      .replace('LINESTRING', '')
      .replace('((', '')
      .replace('(', '')
      .replace('))', '')
      .replace(')', '');
    let polygonStrArr = polygonStr.split(',');
    polygonStrArr = polygonStrArr.map((item) => item.trim());
    let polygon = polygonStrArr.reduce((acc, item) => {
      const [lng, lat] = item.split(' ');
      acc.push({
        lat: Number(lat.trim()),
        lng: Number(lng.trim()),
      });
      return acc;
    }, []);
    return polygon;
  }
  return undefined;
};

export const getLatLngFromString = (latLngStr) => {
  if (latLngStr) {
    const arr = latLngStr.trim().split(',');
    const [lat, lng] = arr || [];
    return {
      lat: Number(lat),
      lng: Number(lng),
    };
  }
  return undefined;
};

export const slugToName = (slug = '') => {
  // remove comma from name and take first string
  return slug?.split('-')?.join(' ');
};

export const nameToSlug = (name = '') => {
  // remove comma from name and take first string
  return name.split(',')?.[0]?.split(' ').join('-');
};

export const queryToSlug = (query = '') => {
  // remove comma from name and take first string
  return query?.split(' ').join('-');
};

export const slugToQuery = (query = '') => {
  // remove comma from name and take first string
  return query?.split('-').join(' ');
};

export const getAreaName = (item) => {
  const areaType = item?.area?.toLowerCase();
  if (areaType === 'city') {
    return item?.city;
  } else if (areaType === 'zip') {
    return item?.zip_code;
  } else if (areaType.includes('subdivision')) {
    return item?.lowest_tier_nbhd || item?.nbhd;
  }
};

export const constructBreadcrumbs = ({ state, county, city, person }) => {
  let breadcrumbs = [
    {
      label: 'United States',
    },
  ];
  if (state?.key && state?.slug) {
    breadcrumbs.push({
      label: state?.key,
      link: `/${state?.slug}`,
    });
  }
  if (county?.key && county?.slug) {
    breadcrumbs.push({
      label: county?.key,
      link: `/${state?.slug}/${county?.slug}`,
    });
  }
  if (city?.key && city?.slug) {
    breadcrumbs.push({
      label: city?.key,
      link: `/${state?.slug}/${county?.slug}/${city?.slug}`,
    });
  }
  if (person) {
    breadcrumbs.push({
      label: person,
    });
  }

  return breadcrumbs;
};

export const isNumber = (str) => {
  if (str) {
    return !isNaN(str);
  }
  return false;
};
