import { combineReducers } from 'redux';
import AgentReducer from './agent';
import AppReducer from './app';

const reducers = {
  app: AppReducer,
  agent: AgentReducer,
};

const combinedReducer = combineReducers(reducers);

export default combinedReducer;
