import { handleData } from '../middlewares';
import Types from '../types/app';

const initialState = {
  dummyEvent: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.DUMMY_REDUX_EVENT: {
      return {
        ...state,
        dummyEvent: `Called_${new Date().getTime()}`,
      };
    }

    case Types.DUMMY_REDUX_ASYNC: {
      return handleData(state, action, {
        request: (prevState) => ({ ...prevState }),
        success: (prevState) => ({
          ...prevState,
          dummyEvent: payload.dummyEvent,
        }),
        failure: (prevState) => ({ ...prevState }),
      });
    }

    default:
      return state;
  }
};
