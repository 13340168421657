import get from 'lodash/get';
import { getAreaName, getPolygonFromString } from 'src/helpers/utilities';
import { handleData } from '../middlewares';
import Types from '../types/agent';

const initialState = {
  segments: {
    data: {},
    loading: false,
    error: null,
  },
  profile: {
    data: {},
    loading: false,
    error: null,
  },
  historyList: {
    loading: false,
    data: [],
    page: 0,
    size: 10,
    total_count: 0,
    total_pages: 0,
    error: '',
  },
  allHistoryList: {
    loading: false,
    data: [],
    page: 0,
    size: 10,
    total_count: 0,
    total_pages: 0,
    error: '',
  },
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    /* Agent Profile */
    case Types.GET_AGENT_PROFILE: {
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          profile: { loading: true, data: {} },
        }),
        success: (prevState) => ({
          ...prevState,
          profile: { loading: false, data: get(payload, 'data') },
        }),
        failure: (prevState) => ({
          ...prevState,
          profile: { loading: false, error: payload },
        }),
      });
    }

    /* Agent Segment */
    case Types.GET_AGENT_SEGMENTS: {
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          segments: { loading: true, data: {} },
        }),
        success: (prevState) => {
          const data = get(payload, 'data.0', {}) || {};
          data.local = (data.local || []).map((item) => {
            item.area = (item.area || '').toLowerCase();
            item.name = getAreaName(item);
            item.polygon = getPolygonFromString(item.actbuy_geo);
            return item;
          });
          data.segments = (data.segments || []).map((item) => {
            item.area = (item.area || '').toLowerCase();
            item.name = getAreaName(item);
            item.polygon = getPolygonFromString(item.actbuy_geo);
            return item;
          });
          return {
            ...prevState,
            segments: { loading: false, data },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          segments: { loading: false, error: payload },
        }),
      });
    }

    /* Agent Map */
    case Types.GET_AGENT_MAP_ASYNC: {
      return handleData(state, action, {
        request: (prevState) => ({ ...prevState }),
        success: (prevState) => ({ ...prevState }),
        failure: (prevState) => ({ ...prevState }),
      });
    }

    case Types.GET_AGENT_HISTORY_LISTING: {
      return handleData(state, action, {
        request: (prevState) => {
          return {
            ...prevState,
            historyList: {
              ...prevState.historyList,
              loading: true,
            },
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            historyList: {
              ...prevState.historyList,
              loading: false,
              data: get(payload, 'data.listings', []) || [],
              page: Number(get(payload, 'data.page', 0)),
              size: get(payload, 'data.size', 10),
              total_count: get(payload, 'data.total_count', 0),
              total_pages: get(payload, 'data.total_pages', 0),
            },
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            historyList: {
              ...prevState.historyList,
              loading: false,
              error: payload,
            },
          };
        },
      });
    }

    case Types.GET_AGENT_ALL_HISTORY_LISTING: {
      return handleData(state, action, {
        request: (prevState) => {
          return {
            ...prevState,
            allHistoryList: {
              ...prevState.allHistoryList,
              loading: true,
            },
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            allHistoryList: {
              ...prevState.allHistoryList,
              loading: false,
              data: get(payload, 'data.listings', []) || [],
              page: Number(get(payload, 'data.page', 0)),
              size: get(payload, 'data.size', 10),
              total_count: get(payload, 'data.total_count', 0),
              total_pages: get(payload, 'data.total_pages', 0),
            },
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            allHistoryList: {
              ...prevState.allHistoryList,
              loading: false,
              error: payload,
            },
          };
        },
      });
    }

    default:
      return state;
  }
};
