import 'normalize.css'; // eslint-disable-line no-restricted-imports
import './index.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import SwiperCore, { Mousewheel, Navigation, Pagination } from 'swiper/core';
import { SwiperSlide } from 'swiper/react';
import Spinner from 'src/components/Spinner';
import Pages from './pages';
import { persistor, store } from './store';

// install Swiper modules
SwiperCore.use([Mousewheel, Pagination, Navigation, SwiperSlide]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Spinner visible />}>
          <Pages />
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
