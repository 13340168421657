import { lazy } from 'react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import AgentList from './AgentList';
// import AgentProfile from './AgentProfile';
// import DummyPage from './Dummy';
// import Home from './Home';
// import PrivacyPolicy from './PrivacyPolicy';
// import TermsAndConditions from './TermsAndConditions';
const AgentList = lazy(() => import('./AgentList'));
const AgentProfile = lazy(() => import('./AgentProfile'));
const DummyPage = lazy(() => import('./Dummy'));
const Home = lazy(() => import('./Home'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));

setConfiguration({
  breakpoints: [576, 768, 1028, 1366, 1600],
  containerWidths: [540, 740, 960, 1250, 1540],
  gutterWidth: 16,
  defaultScreenClass: `xs`,
});

const Pages = () => {
  return (
    <ScreenClassProvider>
      <Router>
        <Switch>
          <Route path="/dummy" exact component={DummyPage} />
          <Route path="/" exact component={Home} />
          <Route path="/agents/:agentId/:slug" exact component={AgentProfile} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route path="/terms" exact component={TermsAndConditions} />
          {/* Keep this route at last */}
          <Route
            path="/:param1/:param2?/:param3?/:param4?/:param5?"
            component={AgentList}
          />
        </Switch>
      </Router>
    </ScreenClassProvider>
  );
};

export default Pages;
