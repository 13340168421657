import React from 'react';
import SpinnerImg from 'src/assets/images/spinner.svg';
import cls from './styles.module.scss';

const Spinner = ({ backgroundColor, visible }) => {
  if (!visible) return null;
  return (
    <div className={cls.spinner} style={{ backgroundColor }}>
      <img src={SpinnerImg} alt="loading..." />
    </div>
  );
};

Spinner.defaultProps = {
  backgroundColor: 'white',
};

export default Spinner;
